import IconArrowRight from '@/assets/icons/icon-arrow-right.svg?component';
import ActionBar from '@/components/ActionBar';
import type { VNode } from 'vue';
import { NuxtLink } from '#components';

export default defineComponent({
  name: 'ActivateSubscriptionBar',
  setup() {
    const route = useRoute();
    const { isFetchedSubscriptions, isSubscriptionSuspended } =
      useCustomerSubscriptions();

    const hideActivateSubscriptionBar = computed<boolean>(
      () =>
        route.name?.toString().startsWith('onboarding') ||
        route.name?.toString().startsWith('delivery-calendar') ||
        route.name?.toString().startsWith('payment-required') ||
        route.name?.toString().startsWith('pay-delivery') ||
        !isFetchedSubscriptions.value ||
        !isSubscriptionSuspended.value
    );

    return {
      isFetchedSubscriptions,
      isSubscriptionSuspended,
      hideActivateSubscriptionBar,
    };
  },
  render(): VNode | null {
    if (this.hideActivateSubscriptionBar) return null;
    return (
      <ActionBar type="activate-subscription">
        <div class="text-content">
          {this.$t('activate_subscription_heading')}
        </div>
        <NuxtLink
          to={{ name: 'delivery-calendar' }}
          class={['button', 'button-primary']}
        >
          {this.$t('activate_subscription_button_text')}
          <IconArrowRight height="24" width="24" class="icon" />
        </NuxtLink>
      </ActionBar>
    );
  },
});
